
/* # Project */
.projects-area {
    padding-top: 65px;
}
.projects-area .container > .section-heading {
    display: none;
}
.projects-area .section-heading {
    justify-content: center;
    margin-bottom: 15px;
}
.projects-area .project-item {
    margin-bottom: 24px;
    padding: 16px 16px 26px 16px;
}
.projects-area .project-item .project-img {
    border-radius: 30px;
    margin-bottom: 13px;
}
.projects-area .project-item .project-img img {
    display: block;
    border-radius: 30px;
}
.projects-area .project-item .project-info p {
    color: #BCBCBC;
    opacity: 0.5;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 2px;
    margin-top: 5px;
}
.projects-area .project-item .project-info h1 {
    font-size: 22px;
    color: #ffffff;
    opacity: 0.9;
    font-weight: 500;
    margin: 0;
}
.projects-area .project-item .project-btn {
    opacity: 0.2;
    transition: .3s;
    display: inline-block;
}
.projects-area .project-item:hover .project-btn {
    opacity: 1;
}
