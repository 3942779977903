/* # Footer */
.footer-area {
    padding-top: 128px;
    padding-bottom: 78px;
}
.main-workspage .footer-area {
    padding-top: 104px;
}
.footer-area .footer-content .logo {
    display: inline-block;
}
.footer-area .footer-content .logo img {
    display: block;
}
.footer-area .footer-content .footer-menu {
    margin: 33px 0 37px 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 44px;
    flex-wrap: wrap;
}
.footer-area .footer-content .footer-menu li a {
    color: #676767;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    font-weight: 600;
    transition: .3s;
}
.footer-area .footer-content .footer-menu li a:hover {
    color: #fff;
}
.footer-area .footer-content .copyright {
    margin: 0;
    color: #727272;
    font-size: 14px;
    font-weight: 500;
}
.footer-area .footer-content .copyright span {
    color: var(--primary_color);
}
