/* # About */
.about-area {
    padding-top: 80px;
}
.about-area .about-me-box {
    position: relative;
    padding: 50px 46px;
    display: flex;
    align-items: flex-start;
    gap: 30px;
}
.about-area .about-me-box .img-box {
    width: 224px;
    height: 224px;
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, #3C58E3 -15%, #C2EBFF 58%, #5AB5E2 97%);
    border-radius: 30px 0 30px 0;
    flex: none;
}
.about-area .about-me-box .img-box img {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.about-area .about-me-box .infos {
    padding-top: 30px;
    position: initial;
}
.about-area .about-me-box .infos h4 {
    color: #BCBCBC;
    opacity: 0.7;
    font-size: 14px;
    letter-spacing: 0.3px;
    margin-bottom: 3px;
}
.about-area .about-me-box .infos h1 {
    font-size: 36px;
    line-height: 40px;
    color: #fff;
    font-weight: 500;
    margin-bottom: 12px;
}
.about-area .about-me-box .infos p {
    letter-spacing: 0.3px;
    margin: 0;
}
.about-area .about-me-box .infos .about-btn {
    display: block;
    position: absolute;
    right: 30px;
    bottom: 30px;
    opacity: 0.2;
    transition: .3s;
}
.about-area .about-me-box:hover .infos .about-btn {
    opacity: 1;
}
.about-area .about-credentials-wrap .banner {
    padding: 17px 25px;
    margin-bottom: 24px;
}
.marquee {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 19px;
    overflow-x: hidden;
}

.marquee div {

    position: absolute;
    white-space: nowrap;
    will-change: transform;
    font-size: 12px;

    animation: marquee 8s linear infinite;
}

.about-area .about-credentials-wrap .banner .marquee b {
    color: #fff;
    font-weight: 400;
}
.about-area .about-credentials-wrap .banner .marquee img {
    display: inline-block;
    margin: 0 5px;
}
.about-area .about-credentials-wrap > .gx-row > * {
    flex: 1;
    width: 50%;
}

.about-area .about-image-box {
    width: 370px;
    flex: none;
    padding: 24px;
    border-radius: 30px;
}
.about-area .about-image-box .image-inner {
    background: linear-gradient(90deg, #3C58E3 -15%, #C2EBFF 58%, #5AB5E2 97%);
    border-radius: 30px;
}
.about-area .about-image-box img {
    display: block;
    border-radius: 30px;
}
.about-area .about-details {
    flex: 1;
}
.about-area .about-details .about-details-inner {
    padding: 115px 60px 30px 30px;
    position: relative;
}
.about-area .about-details .about-details-inner img {
    position: absolute;
    left: 30px;
    top: 0;
}
.about-area .about-details .about-details-inner h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 500;
}
.about-area .about-details .about-details-inner p {
    color: #FFFFFF;
    opacity: 0.8;
    line-height: 25px;
    margin: 0;
    font-size: 16px;
}
.about-area .about-edc-exp {
    padding: 30px;
}
.about-area .about-edc-exp h3 {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 23px;
}
.about-area .about-edc-exp ul li + li {
    margin-top: 32px;
}
.about-area .about-edc-exp ul li .date {
    color: #BCBCBC;
    opacity: 0.6;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
}
.about-area .about-edc-exp ul li h2 {
    font-size: 18px;
    color: #fff;
    opacity: 0.9;
    margin-bottom: 7px;
}
.about-area .about-edc-exp ul li .type {
    color: #BCBCBC;
    font-size: 14px;
    opacity: 0.6;
    font-weight: 400;
    margin: 0;
}

.main-aboutpage .about-area .about-contact-box {
    padding-top: 102px;
}
@keyframes marquee {

    from { transform: translateX(0); }
    to { transform: translateX(-65.3%); }
}