.header-area{
    margin-top: 10px;
}

.header-area .logo {
    display: inline-block;
    z-index: 16;
}
.header-area .logo img {
    display: block;
}
.header-area .navbar {
    margin: 0;
    padding: 0;
}
.header-area .navbar .theme-btn {
    display: none;
}
.header-area .navbar .menu {
    display: flex;
    align-items: center;
    gap: 49px;
    margin: 0;
    padding: 0;
}
.header-area .navbar .menu li a {
    font-size: 16px;
    color: #676767;
    transition: .3s;
    display: block;
    padding: 26px 0px;
}
.header-area .navbar .menu li a.active,
.header-area .navbar .menu li a:hover {
    color: #ffffff;
}
.header-area .show-menu {
    display: none;
    color: #fff;
    font-size: 25px;
    cursor: pointer;
    width: 25px;
    height: 18px;
    flex-direction: column;
    justify-content: space-between;
    z-index: 16;
}
.header-area .show-menu span {
    width: 100%;
    height: 1px;
    background: #fff;
    display: block;
    transition: .4s;
}
.header-area .show-menu.active span:first-child {
    transform: translate3d(0px, 6px, 0px) scale(1, 1) rotate(45deg) scale(1, 1);
}
.header-area .show-menu span:last-child {
    transform-origin: left;
}
.header-area .show-menu.active span:last-child {
    transform: translate3d(4px, -2px, 0px) scale(1, 1) rotate(-45deg) scale(1, 1);
}
.header-area .show-menu.active span:nth-child(2) {
    transform: scale(0);
}



@media (max-width: 767px) {
    .header-area {
        padding: 20px 0;
    }

    .header-area .navbar {
        position: fixed;
        width: 100%;
        background: #0F0F0F;
        z-index: 15;
        overflow-x: hidden;
        /* height: 80px; */
        transform-origin: 50% 50% 0px;
        top: 0;
        left: 0;
        padding-top: 100px;
        padding-bottom: 24px;
        justify-content: center;
        opacity: 0;
        visibility: hidden;
        /* max-height: 0; */
        will-change: transform;

        /* transform: translate3d(0px, -11.4272px, 0px) scale(1, 1) scale(1, 0.945615); */
        transform: translate3d(0px, -300px, 0px) scale(1, 1) scale(1, 0.945615);
        transform: translate3d(0px, -300px, 0px) scale(1, 0.5) scale(1, 0.5);
        transition: .5s
    }
    .header-area .navbar.active {
        opacity: 1;
        visibility: visible;
        /* height: min-content; */
        transform: translate3d(0px, 0, 0px) scale(1, 1) scale(1, 1);
        /* max-height: 90vh; */
    }
    .header-area .navbar .menu {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        width: 100%;
        gap: 30px;
    }
    .header-area .navbar .menu li a {
        padding: 0;
    }
    .header-area .navbar .theme-btn {
        display: inline-block;
        margin-top: 48px;
    }
    .header-area .gx-row > .theme-btn {
        display: none;
    }


    .header-area .show-menu {
        display: flex;
    }
}
